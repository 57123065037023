import React from "react";
import { graphql, PageProps } from "gatsby";
import parse from "html-react-parser";
import Layout from "../components/layout";
import Seo from "../components/seo";

type DataProps = {
  page: {
    id: string;
    title: string;
    excerpt: string;
    content: string;
    date: string;
  };
};

export default function Page({ data: { page } }: PageProps<DataProps>) {
  return (
    <Layout isHome={false}>
      <Seo title={page.title} description={page.excerpt} />
      <article className="mb-24 h-entry entry">
        {page.title ? (
          <>
            <h1 className="text-xl font-bold p-name">{page.title}</h1>
            <time className="dt-published" itemProp="datePublished">
              {page.date}
            </time>
          </>
        ) : (
          <h1 className="font-bold p-name">
            <time className="dt-published" itemProp="datePublished">
              {page.date}
            </time>
          </h1>
        )}
        <div className="e-content entry-body">{parse(page.content)}</div>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query Page($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      content
      date(formatString: "dddd, Do MMM", locale: "sv-SE")
    }
  }
`;
